html h1, html h2 {
  margin-top: 0;
  margin-bottom: 0.5em !important;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

h1.with-button {
  display: flex;
  align-items: center;
}
h1.with-button a {
  color: currentColor;
}

.ant-table-row.no-comment .ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  opacity: 0.3;
  pointer-events: none;
}
